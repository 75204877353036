<template>
    <div class="staffPage">
        <!-- 卓越之星 -->
        <div class="perSonnelBox">
            <!-- 大背景 -->
            <div class="psBigBack" v-if="getAdvData(36).children">
                <a :href="getAdvData(36).children[0].url" target="_blank" rel="noopener noreferrer">
                    <img :src="getAdvData(36).children[0].image" class="pbImg" />
                </a>
            </div>
            <!-- 标题 -->
            <div class="psbTitle">卓越之星</div>
            <!-- 招聘岗位盒子 -->
            <div class="psbContent">
                <!-- 5个招聘盒子 -->
                <div class="pcList">
                    <ul v-if="list && list.length">
                        <li class="pclLi" v-for="(item, index) in list" :key="item.id" @click="goDetail(item)">
                            <img src="https://iv.vu818.com/img/yxyg_bj.png" class="yxyg_bj" ref="yxyg_bj" />
                            <div class="pclInfo">
                                <img :src="item.image" class="yxyg" ref="yxyg" />
                                <p>{{ item.name }}</p>
                                <p>
                                    年终奖：<span
                                        ><i>{{ makeNum(item.year_end_bonus) }} {{ item.year_end_bonus > 10000 ? "w" : "" }}</i>
                                    </span>
                                </p>
                                <p>职业：{{ item.job_title }}</p>
                            </div>
                            <div class="pclTxt">
                                <span>{{ item.intro }}</span>
                                <a @click.prevent>&nbsp;&nbsp;了解详情&nbsp;<span>></span></a>
                            </div>
                        </li>
                    </ul>
                    <img v-if="list && list.length == 0" src="https://iv.vu818.com/img/zwsj.png" style="width: 300px" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "StaffPage",
    inject: ["getAdvData"],
    data() {
        return {
            list: "",
            ll: [],
        };
    },
    created() {
        let _this = this;
        axios.get("/api/excellent_employees/show").then(({ data }) => {
            _this.list = data;
        });
    },
    methods: {
        goDetail(item) {
            this.$router.push({
                path: "/spDetail",
                query: { emSpd: item },
            });
        },
        // 处理数字方法
        makeNum(num = 0) {
            if (num > 9999) {
                return Number((num / 10000).toFixed(1));
            } else {
                return num;
            }
        },
        h(index) {
            this.$nextTick(() => {});
        },
    },
    watch: {
        "$refs.yxyg"() {
            this.list.forEach((e, index) => {
                let [yxyg, yxyg_bj] = [this.$refs.yxyg[index], this.$refs.yxyg_bj[index]];
                ll[index] = yxyg_bj.offsetHeight - yxyg.offsetHeight;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.staffPage {
    width: 100%;
    margin: 0 auto;
    margin-top: 87px;
    // 卓越之星
    .perSonnelBox {
        width: 100%;
        // height: 1045px;
        // 大背景
        .psBigBack {
            width: 100%;
            // max-width: 1440px;
            margin: 0 auto;
            // height: 400px;
            position: relative;
            .pbImg {
                width: 100%;
                // height: 100%;
            }
        }
        // 标题
        .psbTitle {
            height: 100px;
            line-height: 100px;
            font-size: 50px;
            text-align: center;
            margin: 30px auto;
            // padding-top: 431px;
        }
        // 招聘岗位盒子
        .psbContent {
            width: 100%;
            min-height: 413px;
            // 招聘盒子
            .pcList {
                width: 100%;
                max-width: 1440px;
                min-height: 280px;
                margin: 0 auto;
                margin-top: 130px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    .pclLi {
                        width: 30%;
                        position: relative;
                        text-align: left;
                        margin-bottom: 120px;
                        box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.1), -8px -8px 20px 0 #fff;
                        border-radius: 8px;
                        cursor: pointer;
                        .yxyg_bj {
                            position: absolute;
                            width: 100%;
                            top: 0px;
                            left: 0px;
                            z-index: -1;
                        }
                        .yxyg {
                            position: absolute;
                            width: 40%;
                            // top: -115px;
                            bottom: -1px;
                            left: 0px;
                        }
                        .pclInfo {
                            color: white;
                            padding-left: 43%;
                            height: 130px;
                            font-size: 14px;
                            padding-top: 20px;
                            line-height: 26px;
                            position: relative;
                            & > p:nth-of-type(1) {
                                font-size: 18px;
                            }
                            & > p:nth-of-type(2) {
                                span {
                                    font-size: 24px;
                                    letter-spacing: 0;
                                    line-height: 24px;
                                    font-weight: 600;
                                    background-image: linear-gradient(90deg, #ffcf9b, #ffebd5);
                                    color: transparent;
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    padding-right: 10px;
                                }
                            }
                        }
                        .pclTxt {
                            padding: 16px 20px;
                            font-size: 14px;
                            line-height: 26px;
                            & > a {
                                color: #7781f1;
                                display: inline-block;
                                cursor: pointer;
                                & > span {
                                    display: inline-block;
                                    transition: 0.5s;
                                }
                                &:hover {
                                    & > span {
                                        transform: translateX(10px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
